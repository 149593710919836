@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Chicago";
  src: url("./assets/Chicago.ttf") format("truetype");
}

body {
  overflow: hidden;
  background-color: black;
  perspective: 3000px;
  perspective-origin: 50% 50%;
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
